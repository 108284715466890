import Link from "gatsby-link";
import React from "react";

import { button, ButtonVariants } from "./styles.css";

type Props = React.PropsWithChildren & {
  to: string;
  title?: string;
};

export const SimpleButton = ({
  children = "more",
  size,
  ...props
}: Props & ButtonVariants) => {
  return (
    <Link {...props} className={button({ size })}>
      {children}
    </Link>
  );
};
