import { graphql, HeadProps, PageProps } from "gatsby";
import * as React from "react";

import { ArticlePreview } from "../components/article";
import { Hero } from "../components/hero";
import PageLayout from "../components/pageLayout";
import { PageSection } from "../components/pageSection";
import { HeadTemplate } from "../templates/headTemplate";
import { getPaletteFromImageData } from "../util/dynamicThemePalette";

type TemplateProps = PageProps<Queries.AllArticlesQuery>;
type HProps = HeadProps & Pick<TemplateProps, "data">;

const ArticlesPage: React.FC<TemplateProps> = ({ path, data }) => {
  const articles = data.articles?.nodes as Queries.articlePreviewFragment[];

  const heroImage = data.featureImage?.nodes.find(
    (img) => img.featureImage !== null
  )?.featureImage as Queries.SanityRasterImage;

  const dynamicPalette = getPaletteFromImageData(heroImage, "lightVibrant");

  const previews = articles.map((article) => (
    <ArticlePreview articleData={article} key={article.slug.current} />
  ));

  return (
    <PageLayout themeColor={dynamicPalette}>
      <Hero title="Latest News and Articles" bgImage={heroImage} />
      <PageSection variants={{ display: "flex" }}>{previews}</PageSection>
    </PageLayout>
  );
};

export const query = graphql`
  query AllArticles {
    articles: allSanityArticle(
      sort: { order: [DESC, ASC], fields: [date, title] }
    ) {
      nodes {
        ...articlePreview
      }
    }
    featureImage: allSanityArticle(sort: { fields: date, order: DESC }) {
      nodes {
        featureImage {
          alt
          attribution
          ...imageWithPalette
        }
      }
    }
  }
`;

export const Head: React.FC<HProps> = () =>
  HeadTemplate({ title: "Latest news" });

export default ArticlesPage;
