import React from "react";
import PortableText from "react-portable-text";

import { CONSTANTS } from "../../constants";
import { Hero } from "../hero";
import { PageSection } from "../pageSection";
import { SimpleButton } from "../simpleButton";
import { articleStyles } from "./article.css";

type ArticleProps = {
  articleData: Queries.articleFragment;
};

type PreviewProps = {
  articleData: Queries.articlePreviewFragment;
};

type HeaderProps = {
  title?: string;
  subtitle?: string | null;
  date?: string | null;
};

const Header = ({ title, subtitle, date }: HeaderProps) => {
  return (
    <header>
      {title && <h1>{title}</h1>}
      {subtitle && <h2>{subtitle}</h2>}
      {date && <time>{date}</time>}
    </header>
  );
};

const Body = ({ content }: { content: object[] | string | null }) => {
  if (!content) return null;
  const isString = typeof content === "string";
  return isString ? <p>{content}</p> : <PortableText content={content} />;
};

export const ArticlePreview = ({ articleData }: PreviewProps) => {
  const { title, date, subtitle, summary, slug } = articleData;

  return (
    <article className={articleStyles}>
      <Header title={title} subtitle={subtitle} date={date} />
      <Body content={summary} />
      <nav>
        <SimpleButton
          to={`${CONSTANTS.PATH.ARTICLES}/${slug.current}`}
          title={`Read the full article`}
        >
          More
        </SimpleButton>
      </nav>
    </article>
  );
};

export const Article = ({ articleData }: ArticleProps) => {
  const { title, date, subtitle, content } = articleData;
  const featureImage = articleData.featureImage as Queries.SanityRasterImage;
  const headerProps = { date, title, subtitle };

  return (
    <>
      {featureImage && (
        <Hero title={title} subtitle={subtitle} bgImage={featureImage} />
      )}
      <PageSection>
        <article className={articleStyles}>
          <Header {...headerProps} />
          <Body content={content as any} />
        </article>
      </PageSection>
    </>
  );
};
